@import '@amperio/amperio-ui/styles/main';

.home-view-manager-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: spacing(md);
  position: relative;
  width: 100%;

  &__leads-section {
    margin-top: spacing(sm);

    .leads-section__button {
      margin-top: spacing(sm);
    }

    .leads-section__logo {
      display: block;
      justify-content: space-between;
      margin-top: spacing(sm);
    }

    .leads-section__summary {
      margin: spacing(lg) 0;

      &-paper {
        display: flex;
        gap: spacing(md);
      }
    }
  }

  &__alerts-section {
    margin-top: spacing(sm);
    width: 100%;

    .alerts-section__table {
      margin-top: spacing(sm);
    }
  }
}

@include breakpoint(lg) {
  .home-view-manager-wrapper {
    &__alerts-section {
      flex-direction: column;
    }
  }
}

@include breakpoint(md) {
  .home-view-manager-wrapper {
    margin-bottom: spacing(sm);

    &__leads-section {
      margin-top: spacing(xs);

      .leads-section__button {
        margin-top: spacing(xs);
      }

      .leads-section__logo {
        margin-top: spacing(xs);
      }

      .leads-section__summary {
        margin: spacing(md) 0;

        > *:first-child {
          margin-bottom: spacing(xs);
        }

        &-paper {
          flex-direction: column;
          gap: spacing(sm);
        }
      }
    }

    &__alerts-section {
      gap: spacing(sm);
      margin-top: spacing(xs);

      .alerts-section__table {
        margin-top: spacing(xs);
      }
    }
  }
}

@include breakpoint(sm) {
  .home-view-manager-wrapper {
    &__leads-section {
      .leads-section__summary {
        margin: spacing(sm) 0;

        &-paper {
          flex-direction: column;
          margin-top: spacing(sm);
        }
      }
    }
  }
}

@include breakpoint(xs) {
  .home-view-manager-wrapper {
    margin-bottom: spacing(xs);

    &__leads-section {
      .leads-section__summary {
        &-paper {
          margin-top: spacing(xs);
        }
      }
    }

    &__alerts-section {
      gap: spacing(sm);
      margin-top: spacing(xs);
    }
  }
}
