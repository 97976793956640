@import '@amperio/amperio-ui/styles/main';

.leads-summary-paper-wrapper {
  align-items: center;
  display: flex;
  margin-top: spacing(sm) !important;
  max-width: 650px;
  padding: spacing(md);
  width: 100%;

  &__lead-number {
    font-size: 120px;
    margin-right: spacing(md);
  }
}

@include breakpoint(lg) {
  .leads-summary-paper-wrapper {
    &__lead-number {
      font-size: 110px;
    }
  }
}

@include breakpoint(md) {
  .leads-summary-paper-wrapper {
    margin-top: 0 !important;
    max-width: 550px;
    padding: spacing(sm);

    &__lead-number {
      font-size: 90px;
    }
  }
}

@include breakpoint(sm) {
  .leads-summary-paper-wrapper {
    &__lead-number {
      font-size: 80px;
    }
  }
}

@include breakpoint(xs) {
  .leads-summary-paper-wrapper {
    align-items: flex-start;
    flex-direction: column;

    &__lead-number {
      font-size: 70px;
      margin-right: 0;
    }
  }
}
