@import '@amperio/amperio-ui/styles/main';

.dashboard-wrapper {
  display: flex;
  height: 100vh;
  overflow: hidden;
  position: relative;

  &__container {
    height: 100%;
    width: 100%;
  }

  &__content {
    height: calc(100% - 64px);

    &-padding {
      height: 100%;
      padding: spacing(md);
    }

    .simplebar-content {
      height: 100%;
    }
  }

  .modal-wrapper__content {
    width: 550px;
  }
}

@include breakpoint(md) {
  .dashboard-wrapper {
    &__content {
      &-padding {
        padding: spacing(sm);
      }
    }
  }
}

@include breakpoint(sm) {
  .dashboard-wrapper {
    flex-direction: column;

    &__content {
      height: calc(100% - 48px);
    }

    .modal-wrapper__content {
      width: 450px;
    }
  }
}

@include breakpoint(xs) {
  .dashboard-wrapper {
    &__content {
      height: calc(100% - 40px);

      &-padding {
        padding: spacing(xs);
      }
    }

    .modal-wrapper__content {
      width: 90%;
    }
  }
}
