@import '@amperio/amperio-ui/styles/main';

.leads-list-manager-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  &__avatar {
    align-items: center;
    display: flex;
    gap: spacing(xs);
  }

  &__header {
    align-items: flex-end;
    display: flex;
    gap: spacing(md);
    justify-content: flex-end;
    margin-bottom: spacing(md);
    width: 100%;

    > span > .button-wrapper:not(:last-child) {
      margin-right: spacing(sm);
    }
  }

  .dropdown {
    max-width: 300px;
    width: 100%;
  }
}

@include breakpoint(sm) {
  .leads-list-manager-wrapper {
    &__header {
      flex-direction: column;
      gap: 0;
      margin-bottom: spacing(sm);

      > span > .button-wrapper:not(:last-child) {
        margin-right: spacing(sm);
      }
    }
  }
}

@include breakpoint(xs) {
  .leads-list-manager-wrapper {
    &__header {
      flex-direction: column;
      gap: 0;
      margin-bottom: spacing(xs);

      > span > .button-wrapper:not(:last-child) {
        margin-right: spacing(xs);
      }
    }
  }
}
