@import '@amperio/amperio-ui/styles/main';

.leads-form-wrapper {
  display: flex;
  flex-direction: column;
  gap: spacing(xs);
  position: relative;

  &__form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    row-gap: spacing(xs);

    > .MuiFormControl-root {
      width: 32.5%;
    }

    > .phone-input-with-controller-wrapper {
      width: 32.5%;
    }
  }
}

@include breakpoint(sm) {
  .leads-form-wrapper__form {
    flex-direction: column;

    > .MuiFormControl-root {
      width: 100%;
    }

    > .phone-input-with-controller-wrapper {
      width: 100%;
    }
  }
}
