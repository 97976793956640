@import '@amperio/amperio-ui/styles/main';

.lead-info-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;

  &__buttons-container {
    display: flex;
    justify-content: space-between;
    margin-top: spacing(md);
    width: 100%;
  }
}

@include breakpoint(sm) {
  .lead-info-wrapper {
    &__buttons-container {
      margin-top: spacing(sm);
    }
  }
}

@include breakpoint(xs) {
  .lead-info-wrapper {
    &__buttons-container {
      flex-direction: column;

      > .button-wrapper:last-child {
        margin-top: spacing(xs);
      }
    }
  }
}
