@import '@amperio/amperio-ui/styles/main';

.events-view-seller-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  &__header {
    align-items: center;
    column-gap: spacing(sm);
    display: flex;
    justify-content: space-between;

    .header__calendar-actions {
      column-gap: spacing(sm);
      display: flex;
      flex: 1;

      .calendar-actions__arrows {
        align-items: center;
        column-gap: spacing(xs);
        display: flex;
        flex-direction: row;
      }

      .calendar-actions__date {
        align-items: center;
        column-gap: spacing(sm);
        display: flex;
        flex-direction: row;
      }
    }

    .header__events-actions {
      align-items: center;
      column-gap: spacing(md);
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    .header__switch {
      gap: spacing(xs);
      margin-left: 0;

      .MuiTypography-root {
        text-align: center;
        width: 75px;
      }
    }
  }

  .minimal-calendar-wrapper {
    padding: spacing(md) 0;
  }
}

@include breakpoint(md) {
  .events-view-seller-wrapper {
    &__header {
      column-gap: spacing(xs);

      .header__calendar-actions {
        column-gap: spacing(xs);

        .calendar-actions__date {
          column-gap: spacing(xs);
        }
      }

      .header__events-actions {
        column-gap: spacing(sm);
      }
    }

    .minimal-calendar-wrapper {
      padding: spacing(xs) 0;
    }
  }
}

@include breakpoint(sm) {
  .events-view-seller-wrapper {
    &__header {
      display: block;
      margin: 0 auto;
    }

    .minimal-calendar-wrapper {
      padding: spacing(xs) 0 spacing(2xl) 0;
    }
  }
}

@include breakpoint(xs) {
  .events-view-seller-wrapper {
    &__header {
      width: 100%;

      .header__calendar-actions {
        justify-content: center;

        .calendar-actions__date {
          flex-direction: column;
          justify-content: space-evenly;
          row-gap: 0;
        }

        .form-control-label-wrapper {
          flex-direction: column-reverse;
          height: fit-content;
          margin-left: 0;

          .MuiSwitch-root {
            margin: 0;
          }
        }
      }
    }
  }
}
