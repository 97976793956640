@import '@amperio/amperio-ui/styles/main';

.upsert-account-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  &__header {
    display: flex;
    justify-content: space-between;

    > span > .button-wrapper:not(:last-child) {
      margin-right: spacing(md);
    }
  }

  &__content {
    column-gap: spacing(md);
    padding: spacing(md) 0;

    &-top {
      display: grid;
      grid-gap: spacing(md);
      grid-template-columns: repeat(2, 1fr);

      > .account-form-wrapper,
      .address-form-wrapper {
        height: fit-content;
      }
    }

    > .events-table-wrapper {
      height: fit-content;
      margin-top: spacing(md);
    }
  }
}

@include breakpoint(md) {
  .upsert-account-wrapper {
    &__header {
      > span > .button-wrapper:not(:last-child) {
        margin-right: spacing(sm);
      }
    }

    &__content {
      padding: spacing(sm) 0;

      &-top {
        grid-gap: spacing(sm);
        grid-template-columns: 1fr;
      }

      > .events-table-wrapper {
        margin-top: spacing(sm);
      }
    }
  }
}

@include breakpoint(sm) {
  .upsert-account-wrapper {
    &__content {
      padding: 0;
      padding-bottom: spacing(2xl);
    }
  }
}

@include breakpoint(xs) {
  .upsert-account-wrapper {
    &__content {
      &-top {
        grid-gap: spacing(xs);
      }

      > .events-table-wrapper {
        margin-top: spacing(xs);
      }
    }
  }
}
