@import '@amperio/amperio-ui/styles/main';

.summary-form-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  row-gap: spacing(xs);

  &__form {
    display: flex;
    flex-direction: column;
    gap: spacing(sm);
    justify-content: space-evenly;
    position: relative;
  }
}

@include breakpoint(md) {
  .summary-form-wrapper {
    width: 100%;

    &__form {
      flex-direction: column;
      gap: spacing(xs);

      > .MuiFormControl-root {
        width: 100%;
      }
    }
  }
}
