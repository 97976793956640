@import '@amperio/amperio-ui/styles/main';

.upsert-lead-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  &__header {
    display: flex;
    justify-content: space-between;

    > span > .button-wrapper:not(:last-child) {
      margin-right: spacing(sm);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    row-gap: spacing(md);

    &-box {
      display: flex;
      flex-direction: column;
    }

    &-top {
      margin-top: spacing(md);
    }
  }
}

@include breakpoint(md) {
  .upsert-lead-wrapper {
    &__content {
      row-gap: spacing(sm);

      &-top {
        margin-top: spacing(sm);
      }
    }
  }
}

@include breakpoint(sm) {
  .upsert-lead-wrapper {
    &__content-top {
      margin-top: 0;
    }
  }
}

@include breakpoint(xs) {
  .upsert-lead-wrapper {
    &__content {
      row-gap: spacing(xs);
    }
  }
}
