@import '@amperio/amperio-ui/styles/main';

.invitation-form-wrapper {
  display: flex;
  flex-direction: column;

  > .MuiTextField-root:not(:first-child) {
    margin-top: spacing(sm);
  }

  > .button-wrapper {
    margin-top: spacing(md);
  }
}
