@import '@amperio/amperio-ui/styles/main';

.general-form-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  row-gap: spacing(xs);

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .icon-button-wrapper {
      height: fit-content;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: spacing(sm);
    justify-content: space-evenly;
    position: relative;
  }
}

@include breakpoint(xs) {
  .general-form-wrapper__form {
    gap: spacing(xs);
  }
}
