@import '@amperio/amperio-ui/styles/main';

.upsert-event-header-wrapper {
  display: flex;
  justify-content: space-between;

  > span > .button-wrapper:first-child {
    margin-right: spacing(md);
  }
}

@include breakpoint(md) {
  .upsert-event-header-wrapper {
    > span > .button-wrapper:first-child {
      margin-right: spacing(sm);
    }
  }
}
