@import '@amperio/amperio-ui/styles/main';

.home-view-seller-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: spacing(md);
  position: relative;
  width: 100%;

  &__events-section {
    margin-top: spacing(md);

    &-table {
      margin-top: spacing(sm);
    }
  }

  &__calendar-section {
    margin-top: spacing(md);

    .calendar-section__header {
      column-gap: spacing(sm);
      display: flex;
      justify-content: flex-start;
      margin-bottom: spacing(sm);
    }

    .calendar-section__switch {
      margin-left: spacing(xs);
    }

    .minimal-calendar-wrapper {
      padding: 0;
    }
  }

  &__unhandled-events-section {
    margin-top: spacing(md);

    &-table {
      margin-top: spacing(sm);
    }
  }
}

@include breakpoint(md) {
  .home-view-seller-wrapper {
    &__events-section {
      margin-top: spacing(sm);

      &-table {
        margin-top: spacing(sm);
      }
    }

    &__unhandled-events-section {
      margin-top: spacing(sm);

      &-table {
        margin-top: spacing(sm);
      }
    }
  }
}

@include breakpoint(sm) {
  .home-view-seller-wrapper {
    margin-bottom: spacing(sm);

    &__events-section {
      margin-top: spacing(xs);

      &-table {
        margin-top: spacing(xs);
      }
    }

    &__calendar-section {
      margin-top: spacing(sm);

      .calendar-section__header {
        margin-bottom: spacing(xs);
      }
    }

    &__unhandled-events-section {
      margin-top: spacing(xs);

      &-table {
        margin-top: spacing(xs);
      }
    }
  }
}

@include breakpoint(xs) {
  .home-view-seller-wrapper {
    margin-bottom: spacing(xs);

    &__calendar-section {
      margin-top: spacing(sm);

      .calendar-section__header {
        column-gap: spacing(xs);
        margin-bottom: spacing(xxs);
      }
    }
  }
}
