@import '@amperio/amperio-ui/styles/main';

.block-modal-content-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: spacing(sm);
  position: relative;
  text-align: center;

  &__buttons {
    align-items: center;
    display: flex;
    gap: spacing(sm);
    justify-content: space-evenly;
  }
}

@include breakpoint(xs) {
  .block-modal-content-wrapper {
    &__buttons {
      gap: spacing(xs);
    }
  }
}
