@import '@amperio/amperio-ui/styles/main';

.login-wrapper {
  &__form {
    display: flex;
    flex-direction: column;
    gap: spacing(sm);
  }
}

@include breakpoint(xs) {
  .login-wrapper {
    justify-content: start;

    .MuiPaper-root {
      height: 100%;
    }

    &__form {
      min-width: unset;
    }
  }
}
