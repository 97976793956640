@import '@amperio/amperio-ui/styles/main';

.accept-invitation-wrapper {
  max-width: 400px;
  width: 400px;

  &__header {
    margin-bottom: spacing(md);
    text-align: center;
  }

  &__subheader {
    margin-bottom: spacing(md);
    text-align: center;
  }
}

@include breakpoint(sm) {
  .accept-invitation-wrapper {
    &__header {
      margin-bottom: spacing(sm);
    }
  
    &__subheader {
      margin-bottom: spacing(sm);
    }
  }
}

@include breakpoint(xs) {
  .accept-invitation-wrapper {
    max-width: unset;
    width: 100%;

    &__header {
      margin-bottom: spacing(sm);
    }
  
    &__subheader {
      margin-bottom: spacing(sm);
    }
  }
}
