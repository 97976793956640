@import '@amperio/amperio-ui/styles/main';

.mobile-topbar-wrapper {
  align-items: center;
  background-color: palette(primary);
  display: flex;
  justify-content: space-between;
  min-height: calculateRem(64px);
  padding: 0 spacing(sm);
  position: relative;
  width: 100%;

  &__logo {
    height: spacing(xl);
  }

  &__right-container {
    align-items: center;
    display: flex;

    > *:not(:last-child) {
      margin-right: spacing(sm);
    }
  }

  &__menu-icon {
    cursor: pointer;
    transition: opacity 250ms ease-in-out;
    width: spacing(md);

    &:hover {
      @include opacity(0.8);
    }
  }

  &__sidebar {
    background-color: palette(primary);
    display: flex;
    flex-direction: column;
    height: calc(100% - 62px);
    justify-content: space-between;
    pointer-events: all;
    position: fixed;
    right: 0;
    top: calculateRem(62px);
    transform: translateX(0);
    transition: transform 250ms;
    width: 350px;
    z-index: z-index(sidebar);

    &--hidden {
      pointer-events: none;
      transform: translateX(100%);
    }

    &__icon {
      width: spacing(md);

      * {
        stroke: gray(300);
      }

      &--logout {
        height: 20px;
      }
    }

    &__option {
      align-items: center;
      cursor: pointer;
      display: flex;
      padding: spacing(sm);
      transition: opacity 250ms ease-in-out;

      > *:first-child {
        margin-right: spacing(sm);
      }

      &:hover,
      &--active {
        @include opacity(0.5);
      }
    }
  }
}

@include breakpoint(xs) {
  .mobile-topbar-wrapper {
    padding: 0 spacing(xs);

    &__logo {
      height: spacing(lg);
    }

    &__sidebar {
      width: 100%;
    }

    &__right-container {
      > *:not(:last-child) {
        margin-right: spacing(xs);
      }
    }
  }
}
