@import '@amperio/amperio-ui/styles/main';

.create-lead-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  &__header {
    display: flex;
    justify-content: flex-end;

    > span > .button-wrapper:first-child {
      margin-right: spacing(md);
    }
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    padding: spacing(md) 0;
  }
}

@include breakpoint(md) {
  .create-lead-wrapper {
    &__header {
      > span > .button-wrapper:first-child {
        margin-right: spacing(sm);
      }
    }

    &__content {
      display: block;
      padding: spacing(sm) 0;
    }
  }
}

@include breakpoint(sm) {
  .create-lead-wrapper {
    &__content {
      padding: 0;
      padding-bottom: spacing(2xl);
    }
  }
}
